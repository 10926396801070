import { WppActionButton, WppSkeleton, WppTypography, WppTooltip } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { usePostInteractionEvent } from 'api/assistant/mutations/events/usePostInteractionEvent'
import { useFetchApps } from 'api/assistant/queries/apps/useFetchApps'
import { useFetchHubsApi } from 'api/assistant/queries/hubs/useFetchHubsApi'
import DynamicIconApp from 'components/common/icons/DynamicIconApp'
import { BAT_PROD_ID } from 'overrides/tenantIds'
import { AppAPI } from 'types/app/app'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'

import styles from './AppsList.module.scss'
import { useToast } from '../../../hooks/useToast'
import { tooltipConfigLeft } from '../../../utils/tooltipConfig'

export default function AppsList({
  assistantWidth = 440,
  isAiAssistantOpen,
  onMouseEnter,
  isSidebarExpanded,
}: Readonly<{
  isExpanded?: boolean
  assistantWidth?: number
  isAiAssistantOpen?: boolean
  assistantPosition?: { x: number; y: number }
  onMouseEnter?: () => void
  isSidebarExpanded?: boolean
}>) {
  const [appSaved, saveAppState] = useState<AppAPI | null>(null)
  const { osApi, osContext } = useOs()
  const { showToast } = useToast()
  const { data: appsAPI, isLoading: isLoadingApps } = useFetchApps({
    params: { itemsPerPage: 5000 },
  })

  const appsDisplayedList = useMemo(() => {
    if (BAT_PROD_ID === osContext.tenant.id) {
      return appsAPI.filter(
        a =>
          (a.tenantId === osContext.tenant.id || !a.tenantId) &&
          (a.name === 'CS Email Writer' ||
            a.name === 'Focus Group' ||
            a.name === 'Build Personas' ||
            a.name === 'Summarize document' ||
            a.name === 'Content Matrix'),
      )
    }
    return isAiAssistantOpen
      ? appsAPI.filter(a => a.tenantId === osContext.tenant.id || !a.tenantId)
      : appsAPI.filter(a => a.tenantId === osContext.tenant.id || !a.tenantId).slice(0, 5)
  }, [appsAPI, isAiAssistantOpen, osContext.tenant.id])

  const { data, isFetched } = useFetchHubsApi({
    params: {
      page: 1,
      sort: 'name',
      tenantId: osContext.tenant.id,
    },
  })

  const osContextRef = useRef(osContext)
  const dataRef = useRef(data)
  const appSavedRef = useRef(appSaved)

  // Keep refs updated whenever props change
  useEffect(() => {
    osContextRef.current = osContext
    dataRef.current = data
    appSavedRef.current = appSaved
  }, [osContext, data, appSaved])

  const { mutateAsync: createEvent } = usePostInteractionEvent()

  const handleMessages = (event: MessageEvent) => {
    if (event.data.type === 'app-toolbar-context-share-request') {
      const iframe = document.getElementById('app-toolbar-iframe')?.querySelector('iframe')
      iframe?.contentWindow?.postMessage(
        {
          type: 'app-toolbar-context-share',
          context: osContextRef.current,
          userId: osContextRef.current.userDetails.id,
          userEmail: osContextRef.current.userDetails.email,
          userRole: 'OWNER',
          tenantId: osContextRef.current.tenant.id,
          tenantName: osContextRef.current.tenant.name,
          hubId: dataRef.current.length ? dataRef.current[0].id : '',
          hubName: dataRef.current.length ? dataRef.current[0].name : '',
          interactionType: 'tool',
          toolId: appSavedRef.current?.id,
          toolName: appSavedRef.current?.name,
        },
        '*',
      )
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleMessages)
    return () => {
      window.removeEventListener('message', handleMessages)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenApp = (app: AppAPI) => {
    if (app.inProjectContext && !osContext.project) {
      showToast({
        message: 'This application can be launched in the project context only',
        type: 'error',
        duration: 4000,
      })
    } else {
      saveAppState(app)
      openApp(app)
    }
  }

  const handleMouseEnterCallback = useCallback(() => {
    if (onMouseEnter) onMouseEnter()
  }, [onMouseEnter])

  const getOverlayWidth = () => {
    if (isAiAssistantOpen && window.innerWidth > 1366) {
      return 760
    }
    return 600
  }

  const openApp = async (app: AppAPI) => {
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: {
        action: EVENTS.ACTIONS.APP_LAUNCH,
        params: [
          { key: 'productName', value: app.name },
          { key: 'launchedFrom', value: isAiAssistantOpen ? 'AItoolbarMainMenu' : 'AItoolbarMiniMenu' },
          { key: 'productType', value: 'iframe' },
        ],
      },
    })
    try {
      if (isFetched) {
        // Delete unnecessary data from osContext
        const cloneOsContext = JSON.parse(JSON.stringify(osContext))
        delete cloneOsContext.navigationTree
        delete cloneOsContext.theme

        await createEvent({
          context: cloneOsContext,
          userId: osContext.userDetails.id,
          userEmail: osContext.userDetails.email,
          userRole: 'OWNER',
          tenantId: osContext.tenant.id,
          tenantName: osContext.tenant.name,
          hubId: data.length ? data[0].id : '',
          hubName: data.length ? data[0].name : '',
          interactionType: 'tool',
          toolId: app.id,
          toolName: app.name,
          // serviceMention: string,
          // servicePrompt: string,
          // marketId: string,
          // marketName: string,
          // clientId: string,
          // clientName: string,
          // brandId: string,
          // brandName: string,
        })
      }
    } catch (error) {
      console.error('Error opening app', error)
    }

    osApi.navigation.openCompactApp({
      name: app.name,
      url: app.appUrl,
      overlayProps: {
        id: 'app-toolbar-iframe',
        bounds: 'parent',
        // enableUserSelectHack: false,
        default: {
          x: window.innerWidth - (!isAiAssistantOpen ? 44 : assistantWidth + 30) - (getOverlayWidth() || 0),
          y: 24,
          width: getOverlayWidth(),
          height: window.innerHeight - 63 - 48,
        },
        style: {
          zIndex: 50,
        },
        enableResize: app.resizable || false,
        cancel: '.cancel-drag',
      },
    })
  }

  return (
    <>
      {isLoadingApps &&
        Array.from({ length: 5 }).map((_, index) => (
          <div key={index}>
            <WppSkeleton width={24} height={24} animation variant="rectangle" />
          </div>
        ))}
      {!isLoadingApps && (
        <Fragment>
          {appsDisplayedList.map(app => {
            const ButtonContent = (
              <>
                <DynamicIconApp name={app.iconUrl || 'WppIconApp'} slot="icon-start" />
                {isSidebarExpanded && (
                  <WppTypography className={styles.appName} tag="span" type="s-body">
                    {app.name}
                  </WppTypography>
                )}
              </>
            )
            return (
              <Fragment key={app.id}>
                {isSidebarExpanded ? (
                  <WppActionButton
                    className={clsx({ [styles.button]: isSidebarExpanded })}
                    variant="secondary"
                    onClick={() => handleOpenApp(app)}
                    onMouseEnter={handleMouseEnterCallback}
                  >
                    {ButtonContent}
                  </WppActionButton>
                ) : (
                  <WppTooltip
                    key={app.name}
                    className={clsx(styles.conversationItemTooltip, 'cancel-drag')}
                    text={app.name}
                    config={tooltipConfigLeft}
                  >
                    <WppActionButton
                      className={clsx({ [styles.button]: isSidebarExpanded })}
                      variant="secondary"
                      onClick={() => handleOpenApp(app)}
                      onMouseEnter={handleMouseEnterCallback}
                    >
                      {ButtonContent}
                    </WppActionButton>
                  </WppTooltip>
                )}
              </Fragment>
            )
          })}
        </Fragment>
      )}
    </>
  )
}
