import {
  WppIconLike,
  WppIconDislike,
  // WppIconRefresh,
  WppSpinner,
  WppIconCopy,
  WppPill,
} from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import clsx from 'clsx'
import { useState, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { AudienceAnswer } from 'components/assistant/chat/chatBubble/audienceAnswer/AudienceAnswer'
import { BrandAnswer } from 'components/assistant/chat/chatBubble/brandAnswer/BrandAnswer'
import { CampaignAnswer } from 'components/assistant/chat/chatBubble/campaignAnswer/CampaignAnswer'
import styles from 'components/assistant/chat/chatBubble/ChatBubble.module.scss'
import { DataAnswer } from 'components/assistant/chat/chatBubble/dataAnswer/DataAnswer'
import { InsightsAnswer } from 'components/assistant/chat/chatBubble/insightsAnswer/InsightsAnswer'
import { OpenAIAnswer } from 'components/assistant/chat/chatBubble/openAIAnswer/OpenAIAnswer'
import { Question } from 'components/assistant/chat/chatBubble/question/Question'
import { StarfishAnswer } from 'components/assistant/chat/chatBubble/starfishAnswer/StarfishAnswer'
import { Flex } from 'components/common/flex/Flex'
import { useAssistant } from 'hooks/useAssistant'
import { useToast } from 'hooks/useToast'
import { ConversationMessageDto, FeedbackDto } from 'types/dto/ConversationDto'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'

import { FeedbackForm, FeedbackReaction, SubmitFeedbackInterface } from './feedbackForm/FeedbackForm'

interface Props {
  message: ConversationMessageDto
  onOpenFeedback: () => void
  onFeedbackUpdate: (feedback: FeedbackDto) => void
}

export const ChatBubble = ({ message, onOpenFeedback, onFeedbackUpdate }: Props) => {
  const { showToast } = useToast()
  const { t } = useTranslation()

  const [isFeedbackFormVisible, setIsFeedbackFormVisible] = useState(false)
  const [isPositiveFeedbackLoading, setIsPositiveFeedbackLoading] = useState(false)
  const [isNegativeFeedbackLoading, setIsNegativeFeedbackLoading] = useState(false)

  const { addFeedback, patchFeedback, notifyTeam } = useAssistant()

  const baseColor = 'var(--wpp-grey-color-600)'
  const activeColor = 'var(--wpp-primary-color-500)'

  const refBubble = useRef({} as any)

  const isFeedbackPositive = useMemo(() => {
    return message.feedback?.reaction === FeedbackReaction.POSITIVE
  }, [message.feedback?.reaction])

  const isFeedbackNegative = useMemo(() => {
    return message.feedback?.reaction === FeedbackReaction.NEGATIVE
  }, [message.feedback?.reaction])

  const isFeedbackDisplayedPositive = useMemo(() => {
    return isFeedbackPositive && !isFeedbackFormVisible && !isNegativeFeedbackLoading
  }, [isFeedbackPositive, isFeedbackFormVisible, isNegativeFeedbackLoading])

  const isFeedbackDisplayedNegative = useMemo(() => {
    return (isFeedbackFormVisible || isFeedbackNegative) && !isPositiveFeedbackLoading
  }, [isFeedbackFormVisible, isFeedbackNegative, isPositiveFeedbackLoading])

  const isNotifyMessage = useMemo(() => message.type === 'BUTTON_NOTIFY', [message.type])

  const handleDislikeClick = () => {
    if (isFeedbackNegative) return
    setIsFeedbackFormVisible(true)
    onOpenFeedback()

    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: {
        action: EVENTS.ACTIONS.RESPONSE_RATED,
        params: [
          { key: 'messageID', value: message.id },
          { key: 'conversationID', value: message.chatId },
          { key: 'positiveFeedback', value: 'false' },
        ],
      },
    })
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(refBubble?.current?.innerText)
    showToast({
      message: t('toasts.copy_clipboard'),
      type: 'success',
      duration: 4000,
    })
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: {
        action: EVENTS.ACTIONS.RESPONSE_COPIED,
        params: [
          { key: 'messageID', value: message.id },
          { key: 'conversationID', value: message.chatId },
        ],
      },
    })
  }

  const handleNegativeFeedbackClick = (feedbackSubmit: SubmitFeedbackInterface) => {
    if (isPositiveFeedbackLoading || isNegativeFeedbackLoading || isFeedbackNegative) return

    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: {
        action: EVENTS.ACTIONS.FEEDBACK_SUBMITTED,
        params: [
          { key: 'messageID', value: message.id },
          { key: 'conversationID', value: message.chatId },
          { key: 'reason', value: feedbackSubmit.feedbackSelectValue.join(', ') },
          { key: 'review', value: feedbackSubmit.feedbackComment },
        ],
      },
    })

    if (isFeedbackPositive) {
      editNegativeFeedback(feedbackSubmit)
    } else {
      addNegativeFeedback(feedbackSubmit)
    }
  }

  const addNegativeFeedback = (feedbackSubmit: SubmitFeedbackInterface) => {
    setIsFeedbackFormVisible(false)
    setIsNegativeFeedbackLoading(true)
    addFeedback({
      messageId: message.id,
      reaction: FeedbackReaction.NEGATIVE,
      subTypes: feedbackSubmit.feedbackSelectValue,
      feedback: feedbackSubmit.feedbackComment,
    })
      .then((item: any) => {
        showToast({
          message: t('toasts.feedback_successful'),
          type: 'success',
          duration: 4000,
        })
        onFeedbackUpdate(item)
      })
      .catch(() => {
        showToast({
          message: t('toasts.feedback_error'),
          type: 'error',
          duration: 4000,
        })
      })
      .finally(() => {
        setIsNegativeFeedbackLoading(false)
      })
  }

  const editNegativeFeedback = (feedbackSubmit: SubmitFeedbackInterface) => {
    if (!message.feedback?.id) return
    setIsFeedbackFormVisible(false)
    setIsNegativeFeedbackLoading(true)
    patchFeedback({
      feedback_message_id: message.feedback?.id,
      reaction: FeedbackReaction.NEGATIVE,
      subTypes: feedbackSubmit.feedbackSelectValue,
      feedback: feedbackSubmit.feedbackComment,
    })
      .then((item: any) => {
        showToast({
          message: t('toasts.feedback_updated'),
          type: 'success',
          duration: 4000,
        })
        onFeedbackUpdate(item)
      })
      .catch(() => {
        showToast({
          message: t('toasts.feedback_error'),
          type: 'error',
          duration: 4000,
        })
      })
      .finally(() => {
        setIsNegativeFeedbackLoading(false)
      })
  }

  const handlePositiveFeedbackClick = () => {
    if (isPositiveFeedbackLoading || isNegativeFeedbackLoading || isFeedbackPositive) return

    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: {
        action: EVENTS.ACTIONS.RESPONSE_RATED,
        params: [
          { key: 'messageID', value: message.id },
          { key: 'conversationID', value: message.chatId },
          { key: 'positiveFeedback', value: 'true' },
        ],
      },
    })

    if (isFeedbackNegative) {
      editPositiveFeedback()
    } else {
      addPositiveFeedback()
    }
  }

  const addPositiveFeedback = () => {
    setIsPositiveFeedbackLoading(true)
    setIsFeedbackFormVisible(false)
    addFeedback({ messageId: message.id, reaction: FeedbackReaction.POSITIVE })
      .then((item: FeedbackDto) => {
        showToast({
          message: t('toasts.feedback_successful'),
          type: 'success',
          duration: 4000,
        })
        onFeedbackUpdate(item)
      })
      .catch(() => {
        showToast({
          message: t('toasts.feedback_error'),
          type: 'error',
          duration: 4000,
        })
      })
      .finally(() => {
        setIsPositiveFeedbackLoading(false)
      })
  }

  const editPositiveFeedback = () => {
    setIsPositiveFeedbackLoading(true)
    setIsFeedbackFormVisible(false)
    if (!message.feedback?.id) return
    patchFeedback({ feedback_message_id: message.feedback?.id, reaction: FeedbackReaction.POSITIVE })
      .then((item: FeedbackDto) => {
        showToast({
          message: t('toasts.feedback_updated'),
          type: 'success',
          duration: 4000,
        })
        onFeedbackUpdate(item)
      })
      .catch(() => {
        showToast({
          message: t('toasts.feedback_error'),
          type: 'error',
          duration: 4000,
        })
      })
      .finally(() => {
        setIsPositiveFeedbackLoading(false)
      })
  }

  const renderMessage = useMemo(() => {
    switch (message.type) {
      case 'PROMPT':
        return <Question text={message.content} />
      case 'OPEN_API':
        return <OpenAIAnswer text={message.content} />
      case 'FAKE_RESPONSE':
        return <OpenAIAnswer text={message.content} />
      case 'STARFISH':
        return <StarfishAnswer answer={message} />
      case 'GENIES_CAMPAIGN':
        return (
          <CampaignAnswer
            text={message.content.campaign.text}
            recommendation={message.content.recommendation}
            sdQueries={message.content.sdQueries || message.content.sd_queries || []}
          />
        )
      case 'GENIES_DATA':
        return <DataAnswer data={message.content.data} />
      case 'GENIES_BRAND':
        return <BrandAnswer text={message.content} />
      case 'AUDIENCE':
        return <AudienceAnswer text={message.content} />
      case 'GENIES_INSIGHTS':
        return <InsightsAnswer text={message.content} />
      case 'GENIES_SUPER_SEARCH':
        return <InsightsAnswer text={message.content} />
    }
  }, [message])

  return (
    <div>
      <div className={clsx(styles.selectableText, 'cancel-drag')} ref={refBubble}>
        {renderMessage}
      </div>

      {message.type !== 'PROMPT' && !isNotifyMessage && (
        <Flex align="center" className={styles.actionRow}>
          <Flex
            justify="center"
            align="center"
            className={clsx(styles.actionButton, styles.copyButton, 'cancel-drag')}
            onClick={copyToClipboard}
          >
            <WppIconCopy />
          </Flex>
          <Flex
            justify="center"
            align="center"
            className={clsx(
              styles.actionButton,
              styles.positiveButton,
              'cancel-drag',
              isFeedbackPositive && styles.disabled,
            )}
            onClick={handlePositiveFeedbackClick}
          >
            {isPositiveFeedbackLoading ? (
              <WppSpinner size="s" />
            ) : (
              <WppIconLike color={isFeedbackDisplayedPositive ? activeColor : baseColor} />
            )}
          </Flex>
          <Flex
            justify="center"
            align="center"
            className={clsx(
              styles.actionButton,
              styles.negativeButton,
              'cancel-drag',
              isFeedbackNegative && styles.disabled,
            )}
            onClick={() => handleDislikeClick()}
          >
            {isNegativeFeedbackLoading ? (
              <WppSpinner size="s" />
            ) : (
              <WppIconDislike color={isFeedbackDisplayedNegative ? activeColor : baseColor} />
            )}
          </Flex>
          {/* <div className={clsx(styles.actionButton, 'cancel-drag')}>
            <WppIconRefresh />
          </div> */}
        </Flex>
      )}

      {isNotifyMessage && (
        <WppPill className={styles.notifyButton} onClick={notifyTeam}>
          {t('common.notify_my_team')}
        </WppPill>
      )}

      {isFeedbackFormVisible && (
        <FeedbackForm onCloseFeedback={() => setIsFeedbackFormVisible(false)} onSubmit={handleNegativeFeedbackClick} />
      )}
    </div>
  )
}
