export const tooltipConfig: object = {
  arrow:
    '<svg width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">' +
    '<polygon points="0,8 4,4 8,8" fill="#4D5358"/>' +
    '</svg>',
}

export const tooltipConfigLeft: object = {
  arrow:
    '<svg width="8" height="8" viewBox="0 -2 8 8" xmlns="http://www.w3.org/2000/svg">' +
    '<polygon points="0,4 4,0 8,4" fill="#4D5358"/>' +
    '</svg>',
  placement: 'left',
}
