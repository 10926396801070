import { useOs } from '@wpp-open/react'
import { useCallback, useMemo } from 'react'

import { useFetchUserSettings } from 'api/assistant/queries/userSettings/useFetchUserSettings'

//todo: input real option descriptions
const mentionOptionsList: { id: string; display: string; description: string }[] = [
  {
    id: 'appsuggestion',
    display: 'appsuggestion',
    description: 'Locate the perfect app for specific requirements.',
  },
  {
    id: 'campaign',
    display: 'campaign',
    description: 'Acquire comprehensive insights into marketing campaigns.',
  },
  {
    id: 'brand',
    display: 'brand',
    description: 'Grasp deep insights into various brands.',
  },
  {
    id: 'data',
    display: 'data',
    description: 'Access swift and pertinent data insights.',
  },
  {
    id: 'insights',
    display: 'insights',
    description: 'Propose inquiries for insightful revelations on selected subjects.',
  },
  {
    id: 'super-search',
    display: 'super-search',
    description: 'Survey datasets for selected phrases comprehensively.',
  },
]

export const useMentions = () => {
  const { osContext } = useOs()
  const { data: userSettingsAPI } = useFetchUserSettings({
    params: { userId: osContext.userDetails.id },
  })

  const mentionOptions = useMemo(() => {
    return userSettingsAPI.mentionsEnabled ? mentionOptionsList : []
  }, [userSettingsAPI.mentionsEnabled])

  const findMentionUsed = useCallback(
    (prompt: string) => {
      const mentionUsed = mentionOptions.find(mention => prompt.includes(`@${mention.id}`))
      return mentionUsed ? mentionUsed : null
    },
    [mentionOptions],
  )

  return {
    mentionOptions,
    findMentionUsed,
  }
}
