import { ConversationMessageDto } from 'types/dto/ConversationDto'

import { PromptsInterface } from '../chatInstructions/chatInstructionsMockData'

export function timeout(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const parseMessageToString = (message: ConversationMessageDto): string => {
  var finalString = ''
  switch (message.type) {
    case 'PROMPT':
      if (message.role === 'system') return ''
      return message.content
    case 'OPEN_API':
      return message.content
    case 'FAKE_RESPONSE':
      return message.content
    case 'STARFISH':
      finalString =
        message.content.answers?.map(answer => answer.text).join(' ') +
        ' ' +
        message.content.documents?.map(document => document.title).join(' ')
      return finalString
    case 'GENIES_CAMPAIGN':
      finalString = message.content.campaign.text + ' ' + message.content.recommendation
      const queries = message.content.sdQueries || message.content.sd_queries || []
      queries?.forEach(sdQuery => {
        finalString += ' ' + sdQuery
      })
      return finalString
    case 'GENIES_DATA':
      message.content.data.forEach(data => {
        finalString += ' ' + (data.dataWorldContent || data.data_world_content)
      })
      return finalString
    case 'GENIES_BRAND':
      return message.content
    case 'AUDIENCE':
      return message.content
    case 'GENIES_INSIGHTS':
      return message.content
  }
  return ''
}
/**
 * Set the cursor to the end of the element
 * @param element
 */
export const setCursorToEnd = (element: HTMLElement) => {
  const range = document.createRange()
  const selection = window.getSelection()
  range.setStart(element, element.childNodes.length)
  range.collapse(true)
  selection?.removeAllRanges()
  selection?.addRange(range)
}
/**
 * Sort active prompts, set the prompts starting with '@' at the end
 * @param array
 */
export const sortActivePrompts = (array: PromptsInterface[]): PromptsInterface[] => {
  return array.sort((currentPrompt: PromptsInterface, nextPrompt: PromptsInterface) => {
    if (currentPrompt.label.startsWith('@')) return 1
    if (nextPrompt.label.startsWith('@')) return -1
    if (currentPrompt.label < nextPrompt.label) return -1
    if (currentPrompt.label > nextPrompt.label) return 1
    return 0
  })
}
