import { assistantApi } from 'api'
import { CancelableRequestProducer } from 'api/common/types'
import { TaskDto } from 'types/dto/TaskDto'

export interface Props {
  conversationId: string
  question: string
}

export const createQuestion = ({ conversationId, question }: Props): CancelableRequestProducer<TaskDto> =>
  assistantApi.post(`/chats/${conversationId}/messages`, { content: question })
