import { assistantApi } from 'api'
import { CancelableRequestProducer } from 'api/common/types'
import { FeedbackDto } from 'types/dto/ConversationDto'

export interface Props {
  userId: string
  userEmail: string
  userRole: string
  tenantId: string
  tenantName: string
  hubId: string
  hubName: string
  interactionType: 'tool' | 'message'
  toolId?: string
  toolName?: string
  marketId?: string
  marketName?: string
  clientId?: string
  clientName?: string
  brandId?: string
  brandName?: string
  messageMention?: string
  messagePrompt?: string
  context: any
}

export const postInteractionEvent = ({
  userId,
  userEmail,
  userRole,
  tenantId,
  tenantName,
  hubId,
  hubName,
  interactionType,
  toolId,
  toolName,
  marketId,
  marketName,
  clientId,
  clientName,
  brandId,
  brandName,
  messageMention,
  messagePrompt,
  context,
}: Props): CancelableRequestProducer<FeedbackDto> => {
  return assistantApi.post('/interaction-event', {
    userId,
    userEmail,
    userRole,
    tenantId,
    tenantName,
    hubId,
    hubName,
    interactionType,
    toolId,
    toolName,
    marketId,
    marketName,
    clientId,
    clientName,
    brandId,
    brandName,
    messageMention,
    messagePrompt,
    context,
  })
}
