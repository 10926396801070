import {
  WppActionButton,
  WppTypography,
  WppIconClose,
  WppIconChevron,
  WppToastContainer,
} from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import clsx from 'clsx'
import { createRef, memo, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Rnd } from 'react-rnd'

import WppColorLogoIcon from 'assets/icons/AI-button-icon-colored.svg'
import WppWhiteLogoIcon from 'assets/icons/AI-button-icon.svg'
import { AssistantContextMenu } from 'components/assistant/assistantContextMenu/AssistantContextMenu'
import { AssistantContent } from 'components/assistant/assistantPopover/assistantContent/AssistantContent'
import styles from 'components/assistant/assistantPopover/AssistantPopover.module.scss'
import DelayUnmount from 'components/common/DelayUnmount'
import { Flex } from 'components/common/flex/Flex'
import { AssistantTabs } from 'constants/ui'
import { useAssistant } from 'hooks/useAssistant'
import { ConversationDto } from 'types/dto/ConversationDto'
import { UserSettingsDto } from 'types/dto/UserSettingsDto'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'

import { Toolbar } from '../toolbar/Toolbar'

const AssistantHeaderTitles: Record<AssistantTabs, string> = {
  [AssistantTabs.CHAT]: 'ai_assistant',
  [AssistantTabs.HISTORY]: 'history',
  [AssistantTabs.SETTINGS]: 'common.settings',
}

export const AssistantPopover = memo(() => {
  const { t } = useTranslation()

  const DEFAULT_ASSISTANT_POSITION = {
    x: window.innerWidth - 440 - 24,
    y: 24,
  }

  const DEFAULT_ASSISTANT_SIZE = {
    width: '440px',
    height: `${window.innerHeight - 63 - 48}px`,
  }

  useEffect(() => {
    trackAnalytics({
      type: AnalyticsActionType.page,
      payload: EVENTS.SCREENS.BLUE_BUTTON,
    })
  }, [])

  const buttonAssistantRef = createRef<HTMLButtonElement>()

  const { patchUserSettings } = useAssistant()

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const [assistantPosition, setAssistantPosition] = useState(DEFAULT_ASSISTANT_POSITION)
  const [assistantSize, setAssistantSize] = useState(DEFAULT_ASSISTANT_SIZE)

  const [selectedTab, setSelectedTab] = useState<AssistantTabs>(AssistantTabs.CHAT)
  const [selectedConversationHistory, setSelectedConversationHistory] = useState<ConversationDto | undefined>(undefined)
  const [closeHistoryConversation, setCloseHistoryConversation] = useState(false)

  const [clearConversation, setClearConversation] = useState(false)

  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight })

  useEffect(() => {
    if (assistantPosition.x !== windowSize.width - parseInt(assistantSize.width) - 8) {
      setAssistantPosition({
        x: windowSize.width - parseInt(assistantSize.width) - 24,
        y: assistantPosition.y,
      })
    }

    if (assistantSize.height !== `${windowSize.height - 63 - 48}px`) {
      setAssistantSize({
        width: assistantSize.width,
        height: `${windowSize.height - 63 - 48}px`,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.height, windowSize.width])

  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const onSelectTab = (tab: AssistantTabs) => {
    if (tab === AssistantTabs.CHAT) {
      setClearConversation(true)
      setTimeout(() => setClearConversation(false), 1)
      trackAnalytics({
        type: AnalyticsActionType.action,
        payload: EVENTS.ACTIONS.NEW_CONVERSATION,
      })
    }

    if (tab === AssistantTabs.HISTORY && selectedTab !== AssistantTabs.HISTORY) {
      trackAnalytics({
        type: AnalyticsActionType.page,
        payload: EVENTS.SCREENS.HISTORY_SCREEN,
      })
    }

    if (tab === AssistantTabs.SETTINGS && selectedTab !== AssistantTabs.SETTINGS) {
      trackAnalytics({
        type: AnalyticsActionType.page,
        payload: EVENTS.SCREENS.SETTINGS_SCREEN,
      })
    }

    setSelectedTab(tab)
  }

  const onSelectConversationHistory = (conversation: ConversationDto | undefined) => {
    setSelectedConversationHistory(conversation)
  }

  const handleBackClick = () => {
    if (!selectedConversationHistory) {
      setSelectedTab(AssistantTabs.CHAT)
    } else {
      if (selectedTab === AssistantTabs.HISTORY) {
        setCloseHistoryConversation(true)
        setSelectedConversationHistory(undefined)
        setTimeout(() => setCloseHistoryConversation(false), 1)
        trackAnalytics({
          type: AnalyticsActionType.page,
          payload: EVENTS.SCREENS.HISTORY_SCREEN,
        })
      } else {
        setSelectedTab(AssistantTabs.HISTORY)
      }
    }
  }

  const toggleIsPopoverOpen = (isOpen: boolean) => {
    setIsPopoverOpen(isOpen)

    if (isOpen) {
      trackAnalytics({
        type: AnalyticsActionType.page,
        payload: EVENTS.SCREENS.WELCOME_SCREEN,
      })
    }
    if (!isOpen) {
      setTimeout(() => {
        setSelectedTab(AssistantTabs.CHAT)
        setSelectedConversationHistory(undefined)
        setCloseHistoryConversation(false)
        setAssistantPosition(DEFAULT_ASSISTANT_POSITION)
        setAssistantSize(DEFAULT_ASSISTANT_SIZE)
      }, 1000)
    }
  }

  const onUpdateUserSettings = (userSettingsParam: UserSettingsDto) => {
    patchUserSettings({
      settingsId: userSettingsParam.id,
      windowSize: userSettingsParam.windowSize,
      mentionsEnabled: userSettingsParam.mentionsEnabled,
    })
  }

  return (
    <>
      <div id="modal-container" />

      <WppToastContainer id="toast-container" maxToastsToDisplay={5} />
      <Toolbar
        button={
          <button
            hidden={isPopoverOpen}
            className={styles.buttonAssistant}
            onClick={() => {
              trackAnalytics({
                type: AnalyticsActionType.action,
                payload: EVENTS.ACTIONS.BLUE_BUTTON_CLICK,
              })
              toggleIsPopoverOpen(true)
              setAssistantPosition(DEFAULT_ASSISTANT_POSITION)
              setAssistantSize(DEFAULT_ASSISTANT_SIZE)
            }}
            ref={buttonAssistantRef}
          >
            <img src={WppWhiteLogoIcon} style={{ width: 20, height: 20 }} alt="" />
          </button>
        }
      />
      <DelayUnmount delayTime={1000} show={isPopoverOpen}>
        <div className={styles.dragSurface}>
          <Rnd
            minWidth={440}
            minHeight={600}
            bounds="parent"
            position={{ x: assistantPosition.x, y: assistantPosition.y }}
            size={{ width: assistantSize.width, height: assistantSize.height }}
            enableUserSelectHack={false}
            onDragStop={(e, d) => {
              setAssistantPosition({ x: d.x, y: d.y })
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              trackAnalytics({
                type: AnalyticsActionType.action,
                payload: {
                  action: EVENTS.ACTIONS.RESIZE_CHAT,
                  params: [
                    { key: 'height', value: ref.style.height },
                    { key: 'width', value: ref.style.width },
                  ],
                },
              })
              setAssistantSize({
                width: ref.style.width,
                height: ref.style.height,
              })
              setAssistantPosition({ x: position.x, y: position.y })
            }}
            cancel=".cancel-drag"
            className={styles.assistantRnd}
          >
            <div className={`${styles.assistant} ${isPopoverOpen ? styles.opening : styles.closing}`}>
              <Flex className={styles.header} justify="between" align="center" gap={8}>
                <Flex align="center" gap={8} className={styles.flexBaseWidth}>
                  {selectedTab !== AssistantTabs.CHAT && (
                    <WppActionButton variant="secondary" onClick={handleBackClick} className="cancel-drag">
                      <WppIconChevron slot="icon-start" direction="left" />
                    </WppActionButton>
                  )}

                  {selectedTab === AssistantTabs.CHAT && (
                    <img src={WppColorLogoIcon} style={{ width: 30, height: 30, padding: 3 }} alt="WPP AI" />
                  )}

                  <WppTypography type="xl-heading" className={clsx(styles.title, styles.maxWHeader)}>
                    {selectedConversationHistory && selectedTab === AssistantTabs.HISTORY
                      ? selectedConversationHistory.name
                      : t(AssistantHeaderTitles[selectedTab])}
                  </WppTypography>
                </Flex>
                <Flex align="center" gap={2}>
                  <AssistantContextMenu onSelect={onSelectTab} />
                  <WppActionButton
                    variant="secondary"
                    onClick={() => {
                      trackAnalytics({
                        type: AnalyticsActionType.action,
                        payload: EVENTS.ACTIONS.EXIT_CHAT,
                      })
                      toggleIsPopoverOpen(false)
                    }}
                    className="cancel-drag"
                  >
                    <WppIconClose slot="icon-start" color="var(--wpp-grey-color-800)" />
                  </WppActionButton>
                </Flex>
              </Flex>

              <AssistantContent
                selectedTabOuter={selectedTab}
                onSelectTabOuter={onSelectTab}
                onSelectConversation={onSelectConversationHistory}
                closeHistoryConversation={closeHistoryConversation}
                clearConversation={clearConversation}
                onUpdateUserSettings={onUpdateUserSettings}
                assistantWidth={parseInt(assistantSize.width)}
                isAiAssistantOpen={isPopoverOpen}
                assistantPosition={assistantPosition}
              />
            </div>
          </Rnd>
        </div>
      </DelayUnmount>
    </>
  )
})
