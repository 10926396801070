// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wp3Xk{cursor:text}.I5bVC{cursor:pointer;width:32px;height:32px;border-radius:var(--wpp-border-radius-s)}.s_pew{margin-bottom:8px}.D1U5B{cursor:not-allowed}.EN_28{margin-bottom:8px}.fIgal:hover{background-color:var(--wpp-grey-color-300)}.Fm6Vn:hover{background-color:var(--wpp-grey-color-300)}.uquXU:hover{background-color:var(--wpp-grey-color-300)}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatBubble/ChatBubble.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CAGF,OACE,cAAA,CACA,UAAA,CACA,WAAA,CACA,wCAAA,CAGF,OACE,iBAAA,CAGF,OACE,kBAAA,CAGF,OACE,iBAAA,CAGF,aACE,0CAAA,CAGF,aACE,0CAAA,CAGF,aACE,0CAAA","sourcesContent":[".selectableText {\n  cursor: text;\n}\n\n.actionButton {\n  cursor: pointer;\n  width: 32px;\n  height: 32px;\n  border-radius: var(--wpp-border-radius-s);\n}\n\n.actionRow {\n  margin-bottom: 8px;\n}\n\n.disabled {\n  cursor: not-allowed;\n}\n\n.notifyButton {\n  margin-bottom: 8px;\n}\n\n.copyButton:hover {\n  background-color: var(--wpp-grey-color-300);\n}\n\n.negativeButton:hover {\n  background-color: var(--wpp-grey-color-300);\n}\n\n.positiveButton:hover {\n  background-color: var(--wpp-grey-color-300);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectableText": "wp3Xk",
	"actionButton": "I5bVC",
	"actionRow": "s_pew",
	"disabled": "D1U5B",
	"notifyButton": "EN_28",
	"copyButton": "fIgal",
	"negativeButton": "Fm6Vn",
	"positiveButton": "uquXU"
};
export default ___CSS_LOADER_EXPORT___;
