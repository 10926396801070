import { assistantApi } from 'api'
import { CancelableRequestProducer } from 'api/common/types'
import { FeedbackReaction, FeedbackSelectOptions } from 'components/assistant/chat/chatBubble/feedbackForm/FeedbackForm'
import { FeedbackDto } from 'types/dto/ConversationDto'

export interface Props {
  userId: string
  messageId: string
  reaction: FeedbackReaction
  subTypes: FeedbackSelectOptions[] | null
  feedback: string | null
}

export const createFeedback = ({
  userId,
  messageId,
  reaction,
  subTypes,
  feedback,
}: Props): CancelableRequestProducer<FeedbackDto> => {
  return assistantApi.post('/feedback-messages', { userId, messageId, reaction, subTypes, feedback })
}
