import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import React, { KeyboardEvent, MouseEvent, RefObject, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchUserSettings } from 'api/assistant/queries/userSettings/useFetchUserSettings'
import styles from 'components/assistant/chat/questionInput/QuestionInput.module.scss'
import { useMentions } from 'hooks/useMentions'

import InputActions from '../inputActions/InputActions'
import { setCursorToEnd } from '../utils/utils'

interface QuestionInterfaceProps {
  enableEdit: boolean
  defaultValue: string
  onInput: () => void
  onQuestionKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void
  onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void
  inputRef: RefObject<HTMLDivElement>
  questionCharCount: number
  question: string
  answerIsLoading: boolean
}

export const QuestionInput = ({
  enableEdit,
  defaultValue,
  onInput,
  onQuestionKeyDown,
  onMouseDown,
  inputRef,
  questionCharCount,
  question,
  answerIsLoading,
}: QuestionInterfaceProps) => {
  const { t } = useTranslation()
  const { osContext } = useOs()
  const { findMentionUsed } = useMentions()
  const [questionPlaceholder, setQuestionPlaceholder] = useState(t('question_input.placeholder'))

  const { data: userSettingsAPI } = useFetchUserSettings({
    params: { userId: osContext.userDetails.id },
  })

  useEffect(() => {
    setQuestionPlaceholder(
      userSettingsAPI.mentionsEnabled ? t('question_input.placeholder_mentions') : t('question_input.placeholder'),
    )
  }, [t, userSettingsAPI.mentionsEnabled])

  const handleInput = () => {
    onInput()
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    onQuestionKeyDown(event)
  }

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    onMouseDown(event)
  }

  function replacePasteText(originalText: string, firstIndex: number, lastIndex: number, replacement: string) {
    return originalText.substring(0, firstIndex) + replacement + originalText.substring(lastIndex, originalText.length)
  }

  const handlePaste = (e: any) => {
    try {
      if (
        findMentionUsed(inputRef?.current?.innerText || '') ||
        (inputRef?.current?.childNodes.length && inputRef?.current?.childNodes.length > 1)
      ) {
        return
      }
      e.preventDefault()

      const selection = window.getSelection() as any
      if (selection) {
        const firstIndex = Math.min(selection?.baseOffset, selection?.extentOffset)
        const lastIndex = Math.max(selection?.baseOffset, selection?.extentOffset)

        const text = e.clipboardData.getData('text/plain')

        if (inputRef.current) {
          const newText = replacePasteText(inputRef.current.innerText, firstIndex, lastIndex, text)
          inputRef.current.innerText = newText

          setCursorToEnd(inputRef.current)
          onInput()
        }
      }
    } catch (error) {
      console.error(error)
      onInput()
    }
  }

  return (
    <div className={styles.inputContainer}>
      <div
        ref={inputRef}
        contentEditable={enableEdit}
        className={clsx(styles.questionInput, !enableEdit && styles.disabled)}
        placeholder={questionPlaceholder}
        onKeyDown={handleKeyDown}
        onInput={handleInput}
        onMouseDown={handleMouseDown}
        suppressContentEditableWarning={true}
        dangerouslySetInnerHTML={{ __html: defaultValue }}
        onPaste={handlePaste}
      />
      <InputActions question={question} questionCharCount={questionCharCount} answerIsLoading={answerIsLoading} />
    </div>
  )
}
