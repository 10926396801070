import { createApi } from 'api/common/createApi'
import config, { API_TYPES } from 'constants/app-config'

export const assistantApi = createApi({
  baseURL: config[API_TYPES.ASSISTANT_API],
})

export const facadeApi = createApi({
  baseURL: '/api',
})
