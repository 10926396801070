import { WppTypography, WppToggle } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchUserSettings } from 'api/assistant/queries/userSettings/useFetchUserSettings'
import styles from 'components/assistant/assistantPopover/assistantUserSettings/AssistantUserSettings.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { UserSettingsDto } from 'types/dto/UserSettingsDto'

export enum AssistantSizes {
  S = 'small',
  M = 'medium',
  L = 'large',
}

interface Props {
  onUpdateUserSettings: (userSettings: UserSettingsDto) => void
}

export const AssistantUserSettings = ({ onUpdateUserSettings }: Props) => {
  const { t } = useTranslation()
  const { osContext } = useOs()

  const { data: userSettingsAPI } = useFetchUserSettings({
    params: { userId: osContext.userDetails.id },
  })

  const [settings, setSettings] = useState(userSettingsAPI)

  useEffect(() => {
    setSettings(userSettingsAPI)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettingsAPI.windowSize])

  return (
    <div style={{ paddingLeft: 8, height: '100%', width: 'calc(100% - 20px)' }}>
      <Flex direction="column" gap={24} className={styles.mt16}>
        <Flex direction="column" gap={8}>
          <WppTypography tag="h2" type="m-strong" className={styles.title}>
            {t('settings.mentions')}
          </WppTypography>
          <WppTypography tag="p" type="xs-body" className={styles.text}>
            {t('settings.mentions_caption')}
          </WppTypography>

          <WppToggle
            className={clsx(styles.toggle, 'cancel-drag')}
            checked={settings.mentionsEnabled}
            labelConfig={{ text: t('settings.mentions_action') }}
            required
            onWppChange={({ detail: { checked } }) => {
              setSettings(prevState => ({
                ...prevState,
                mentionsEnabled: checked,
              }))
              onUpdateUserSettings({ ...settings, mentionsEnabled: checked })
            }}
          />
        </Flex>
      </Flex>
    </div>
  )
}
