// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tjltj{width:auto !important}.Bd6yh{margin:4px 0}", "",{"version":3,"sources":["webpack://./src/components/assistant/savedConversationsList/savedConversationsListItem/savedConversationListItemMenu/SavedConversationListItemMenu.module.scss"],"names":[],"mappings":"AAAA,OACE,qBAAA,CAGF,OACE,YAAA","sourcesContent":[".wAuto {\n  width: auto !important;\n}\n\n.divider {\n  margin: 4px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wAuto": "Tjltj",
	"divider": "Bd6yh"
};
export default ___CSS_LOADER_EXPORT___;
