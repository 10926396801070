import { WppPill, WppTooltip } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchUserSettings } from 'api/assistant/queries/userSettings/useFetchUserSettings'
import styles from 'components/assistant/chat/chatInstructions/ChatInstructions.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { BAT_PROD_ID, HELKEL_TENANT_ID, VMLY_HULK_TENANT_ID } from 'overrides/tenantIds'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'

import { PromptsData, PromptsInterface, PromptsDataMentions, PromptsDataCustomClient } from './chatInstructionsMockData'
import { tooltipConfig } from '../../../../utils/tooltipConfig'
import { OpenAIAnswer } from '../chatBubble/openAIAnswer/OpenAIAnswer'

interface Props {
  question: string
  onPromptClick: (prompt: string) => void
}

export const ChatInstructions = ({ question, onPromptClick }: Props) => {
  const { osContext } = useOs()
  const { t } = useTranslation()

  const { data: userSettingsAPI } = useFetchUserSettings({
    params: { userId: osContext.userDetails.id },
  })

  const [activePrompts, setActivePrompts] = useState<PromptsInterface[]>([])

  const [activePill, setActivePill] = useState('')
  const [activePillQuestion, setActivePillQuestion] = useState('')

  useEffect(() => {
    if (BAT_PROD_ID === osContext.tenant.id) {
      setActivePrompts(PromptsDataCustomClient)
      return
    }
    setActivePrompts(userSettingsAPI.mentionsEnabled ? [...PromptsData, ...PromptsDataMentions] : PromptsData)
  }, [osContext.tenant.id, userSettingsAPI.mentionsEnabled])

  useEffect(() => {
    const activePillQuestionWithoutBrackets = activePillQuestion.replace(/[[\]]/g, '')
    if (activePillQuestionWithoutBrackets !== question) {
      setActivePill('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question])

  const handlePromptClick = (prompt: PromptsInterface) => {
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: {
        action: EVENTS.ACTIONS.PILL_CLICK,
        params: [{ key: 'pill_text', value: prompt.prompt }],
      },
    })

    setActivePillQuestion(prompt.prompt)
    setActivePill(prompt.value)
    onPromptClick(prompt.prompt)
  }

  return (
    <div className={styles.instructions}>
      <div className={clsx(styles.selectable, 'cancel-drag')}>
        <OpenAIAnswer text={t('how_to_use.welcome_message')} />
      </div>

      {osContext.tenant.id !== HELKEL_TENANT_ID && osContext.tenant.id !== VMLY_HULK_TENANT_ID && (
        <Flex wrap="wrap" gap={8}>
          {activePrompts.map((prompt: PromptsInterface, index: number) => {
            return prompt.tooltip ? (
              <WppTooltip
                key={index}
                className={clsx(styles.conversationItemTooltip, 'cancel-drag')}
                text={prompt.tooltip}
                config={tooltipConfig}
              >
                <WppPill
                  label={prompt.label}
                  value={prompt.value}
                  checked={prompt.value === activePill}
                  onClick={() => handlePromptClick(prompt)}
                />
              </WppTooltip>
            ) : (
              <WppPill
                key={index}
                label={prompt.label}
                value={prompt.value}
                checked={prompt.value === activePill}
                onClick={() => handlePromptClick(prompt)}
              />
            )
          })}
        </Flex>
      )}

      {/* <WppPillGroup type="single" onWppChange={handlePillGroupChange}> */}

      {/* </WppPillGroup> */}

      {/* <Flex wrap="wrap" gap={8}>
        {activePrompts.map((prompt: PromptsInterface, index: number) => {
          return prompt.tooltip ? (
            <WppTooltip className={clsx(styles.conversationItemTooltip, 'cancel-drag')} text={prompt.tooltip}>
              <div
                key={index}
                className={clsx(styles.exampleCard, 'cancel-drag')}
                onClick={() => onPromptClick(prompt.prompt)}
              >
                <WppTypography type="s-body" tag="p">
                  {prompt.label}
                </WppTypography>
              </div>
            </WppTooltip>
          ) : (
            <div
              key={index}
              className={clsx(styles.exampleCard, 'cancel-drag')}
              onClick={() => onPromptClick(prompt.prompt)}
            >
              <WppTypography type="s-body" tag="p">
                {prompt.label}
              </WppTypography>
            </div>
          )
        })}
      </Flex> */}
    </div>
  )
}
