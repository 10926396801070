// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eqax2{width:100%;height:100%;border:none;border-radius:var(--wpp-border-radius-l)}.Hqncr{width:100%;height:calc(100% - 58px);border-radius:var(--wpp-border-radius-l)}.KjouY::part(icon-start){fill:#4d5358}.WOThL{display:block;width:100%}.WOThL::part(anchor){display:block;width:100%}.WOThL::part(inner){display:block}.XnCg1{font-weight:400;margin-left:6px}.XnCg1::part(typography){overflow:hidden;text-overflow:ellipsis;white-space:nowrap;width:188px;text-align:left}.hsUsx{width:100%}", "",{"version":3,"sources":["webpack://./src/components/assistant/apps/AppsList.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,WAAA,CACA,WAAA,CACA,wCAAA,CAGF,OACE,UAAA,CACA,wBAAA,CACA,wCAAA,CAIA,yBACE,YAAA,CAIJ,OACE,aAAA,CACA,UAAA,CAEA,qBACE,aAAA,CACA,UAAA,CAGF,oBACE,aAAA,CAIJ,OACE,eAAA,CACA,eAAA,CACA,yBACE,eAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,eAAA,CAKJ,OACE,UAAA","sourcesContent":[".iframe {\n  width: 100%;\n  height: 100%;\n  border: none;\n  border-radius: var(--wpp-border-radius-l);\n}\n\n.iframeContainer {\n  width: 100%;\n  height: calc(100% - 58px);\n  border-radius: var(--wpp-border-radius-l);\n}\n\n.toolbarIcon {\n  &::part(icon-start) {\n    fill: #4D5358\n  }\n}\n\n.conversationItemTooltip {\n  display: block;\n  width: 100%;\n\n  &::part(anchor) {\n    display: block;\n    width: 100%;\n  }\n\n  &::part(inner) {\n    display: block;\n  }\n}\n\n.appName {\n  font-weight: 400;\n  margin-left: 6px;\n  &::part(typography) {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    width: 188px;\n    text-align: left;\n  }\n\n}\n\n.button {\n  width: 100%;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iframe": "eqax2",
	"iframeContainer": "Hqncr",
	"toolbarIcon": "KjouY",
	"conversationItemTooltip": "WOThL",
	"appName": "XnCg1",
	"button": "hsUsx"
};
export default ___CSS_LOADER_EXPORT___;
